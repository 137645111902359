<template>
  <div>
    <v-dialog
      v-model="viewDialog"
      persistent
      max-width="700px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          <!-- {{ $t('Receipt Invoice') }}: {{ data.invoice_no }} -->
        </v-card-title>

        <v-card-text v-if="viewDialog">
          <v-simple-table
            id="print-area"
            class="print-table"
            dense
          >
            <template #default>
              <thead>
                <invoice-header
                  :date="data.action_date"
                  :invoice-no="data.invoice_no"
                />
              </thead>

              <tbody class="mt-4">
                <tr>
                  <td
                    colspan="100"
                    style="border: none; height: 10px"
                  />
                </tr>
                <tr>
                  <td
                    colspan="100"
                    class="rounded py-3"
                    style="border: 1px solid #000"
                  >
                    <table>
                      <tbody>
                        <!-- <tr>
                          <td
                            colspan="100"
                            class="text-center text-subtitle-1 font-weight-bold pb-4"
                          >
                            {{ $t('Receipt Invoice') }}
                          </td>
                        </tr> -->

                        <tr>
                          <td
                            colspan="100"
                            class="bottom-border"
                          >
                            <table>
                              <tr>
                                <td style="width: 65%">
                                  <table>
                                    <tr>
                                      <td class="text-start h-35px text-body-1">
                                        <div class="d-block text-center px-4 rounded black-border">
                                          {{ $_format_number(data.old_loan) }} $
                                        </div>
                                      </td>
                                      <td class="text-start px-4 h-35px text-body-1">
                                        <b>{{ $t('Old Loan') }}</b>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-start h-35px text-body-1">
                                        <div class="d-block text-center px-4 rounded black-border">
                                          {{ $_format_number(data.amount) }} $
                                        </div>
                                      </td>
                                      <td class="text-start px-4 h-35px text-body-1">
                                        <b>{{ $t('Receipt Amount') }}</b>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-start h-35px text-body-1">
                                        <div class="d-block text-center px-4 rounded black-border">
                                          {{ $_format_number(data.current_loan) }} $
                                        </div>
                                      </td>
                                      <td class="text-start px-4 h-35px text-body-1">
                                        <b>{{ $t('Current Loan') }}</b>
                                      </td>
                                    </tr>
                                  </table>
                                </td>

                                <td class="text-end h-35px text-body-1">
                                  <b>{{ $t('Receipt Count') }}:</b> {{ $_format_number(data.receipt_count) }}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="100">
                            <table>
                              <tr>
                                <td style="width: 65%">
                                  <table>
                                    <tr>
                                      <td
                                        class="text-start px-4 h-35px text-body-1"
                                        style="width: 200px"
                                      >
                                        <b>{{ $t('Receipt from Mr.') }}</b>
                                      </td>
                                      <td class="text-start h-35px text-body-1 bottom-dashed-border">
                                        <div class="d-block text-center px-4">
                                          {{ data.customer.name }}
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        class="text-start px-4 h-35px text-body-1"
                                        style="width: 200px"
                                      >
                                        <b>{{ $t('Monthly Receipt Amount') }}</b>
                                      </td>
                                      <td class="text-start h-35px text-body-1 bottom-dashed-border">
                                        <div class="d-block text-center px-4">
                                          {{ $_format_number(data.monthly_receipt_amount) }} $
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </td>

                                <td class="text-end h-35px text-body-1">
                                  <b>{{ $t('Next Receipt Date') }}:</b> {{ $_format_date(data.next_receipt_date) }}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="100">
                            <table>
                              <tr>
                                <td
                                  style="width: 20%"
                                  class="text-start text-body-1 h-35px pt-6 ps-5"
                                >
                                  <b>لە بری</b>
                                </td>
                                <td class="text-start h-35px pt-6 ps-3 bottom-dashed-border">
                                  {{ data?.last_sale?.item_name }}
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td
                            colspan="100"
                            class="text-center h-35px pt-6"
                          >
                            <b>{{ $t('User Signature') }}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="$emit('close')"
          >
            {{ $t('Cancel') }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            small
            @click="$_print()"
          >
            <v-icon left>
              mdil-printer
            </v-icon>
            <span class="pe-2">
              {{ $t('Print') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    viewDialog: { type: Boolean, default: false },
    data: { type: Object, default: () => { } },
  },

  watch: {
    viewDialog(val) {
      if (val && this.data.doPrint) {
        this.$_print()
      }
    }
  }
}
</script>
<style>
.h-35px {
  height: 35px !important;
}
.w-100 {
  width: 100% !important;
}
.border-none {
  border: none !important;
}
</style>